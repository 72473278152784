// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start();
Turbolinks.start();
ActiveStorage.start();
require("jquery");

document.addEventListener("turbolinks:load", function () {
  $('.slider').slick({
    autoplay: true,//自動的に動き出すか。初期値はfalse。
    infinite: true,//スライドをループさせるかどうか。初期値はtrue。
    rows: 2,
    slidesPerRow: 4,
    prevArrow: '<div class="slick-prev"></div>',//矢印部分PreviewのHTMLを変更
    nextArrow: '<div class="slick-next"></div>',//矢印部分NextのHTMLを変更
    dots: false,//下部ドットナビゲーションの表示
    responsive: [
      {
      breakpoint: 769,//モニターの横幅が769px以下の見せ方
        settings: {
          slidesToShow: 2,//スライドを画面に2枚見せる
          slidesToScroll: 2,//1回のスクロールで2枚の写真を移動して見せる
        }
      },
      {
        breakpoint: 480,//モニターの横幅が426px以下の見せ方
        settings: {
          slidesPerRow: 3
        }
      },
    ],
    variableWidth: false
  });
});

document.addEventListener("turbolinks:load", function () {
  $(function(){
    $('.asset_image').on('click',function(){
      $('.asset_video').remove();
      var num = $(this).children('img').attr('class');
      $(document).ajaxSend(function() {
        $("#overlay").fadeIn(100);
      });
      $.ajax({
        type: "GET",
        url: `/asset/${num}`
      }).done(function() {
        setTimeout(function(){
          $("#overlay").fadeOut(200);
        },300);
      });
    })
  })
});


$(document).on('turbolinks:load', function() {
  function scroll_control(event) {
    event.preventDefault();
  }
  //変数の設定
  var $body = $('boby');

  //スクロール量を保存
  var scrollTop;

  //スクロールを固定
  function bodyFixedOn() {
    scrollTop = $(window).scrollTop();
    $body.css({
      position: 'fixed',
      top: -scrollTop
    });
  }

  //スクロールの固定を解除
  function bodyFixedOff() {
    $body.css({
      position: '',
      top: ''
    });

    $(window).scrollTop(scrollTop);
  }


  const ham = $('#menu_list');
  const nav = $('#js-nav');
  ham.on('click', function () {
    ham.toggleClass('active');
    nav.toggleClass('active');
    if ($(ham).hasClass('active')) {
      bodyFixedOn()
    } else {
      bodyFixedOff()
    }
  });
});

// $(document).on('turbolinks:load', function() {
//   $(function(){
//     var pagetop = $('#page_top');
//     pagetop.hide();
//     $(window).scroll(function () {
//        if ($(this).scrollTop() > 100) {
//             pagetop.fadeIn();
//        } else {
//             pagetop.fadeOut();
//        }
//     });
//     pagetop.click(function () {
//        $('body, html').animate({ scrollTop: 0 }, 500);
//        return false;
//     });
//   });
// });

// $(document).on('turbolinks:load', function() {
//   $(function() {
//     const height=$("#header").height();
//     $(".content").css("padding-top", height + 20);
//   });
// });

// $(document).on('turbolinks:load', function() {
//   $(function () {
//     $('.modal_open').click(function () {
//       $("body").addClass("no_scroll"); // 背景固定させるクラス付与
//       var id = $(this).data('id'); // 何番目のキャプション（モーダルウィンドウ）か認識
//       $('#overlay, .modal_window[data-id="' + id + '"]').fadeIn();
//     });

//     $('.modal_close').click(function () {
//       $("body").removeClass("no_scroll"); // 背景固定させるクラス削除
//       $('.overlay').fadeOut();
//       $('.modal_window').fadeOut();
//     });
//   });
// });

// $(document).on('turbolinks:load', function() {
//   $(function(){
//     $(".content").css("display", "none");
//     setTimeout(function() {
//         $('.top_image').fadeOut();
//       }, 3000);
//     });

//     $(function(){
//         $(".content").css({opacity:'0'});
//         setTimeout(function(){
//         $(".content").css("display", "block");
//         $(".content").stop().animate({opacity:'1'},1000);
//         },3000);
//     });
// });

$(document).on('turbolinks:load', function() {
  $(function(){
    $(window).on('load scroll',function (){
      $('.element').each(function(){
        //ターゲットの位置を取得
        var target = $(this).offset().top;
        //スクロール量を取得
        var scroll = $(window).scrollTop();
        //ウィンドウの高さを取得
        var height = $(window).height();
        //ターゲットまでスクロールするとフェードインする
        if (scroll > target - height){
          //クラスを付与
          $(this).addClass('active');
        }
      });
    });
  });
});
